import { Layout } from 'antd';

type Props = {
  path: string;
};

const HomePage = (props: Props) => (
  <Layout
    style={{
      width: '100%',
      height: '100%',
    }}
  >
    <iframe
      className="homepage-iframe"
      id="homepage_static"
      title="homepage"
      width="100%"
      height="100%"
      src={`https://www.dev.dimdocks.com/${props.path}`}
    />
  </Layout>
);

export default HomePage;
