import { Typography } from 'antd';
import { PageContainer } from '../../theme/Global.css';

const { Title } = Typography;

const ProjectsPage = () => (
  <PageContainer>
    <Title>Projects</Title>
  </PageContainer>
);

export default ProjectsPage;
