import { DashboardRounded } from '@mui/icons-material';
import { Button, Drawer, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd/es/menu';
import { Amplify, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import awsmobile from './aws-exports';
import getIcon from './components/icons/icons';
import NavBar from './components/navbar/NavBar';
import DashboardPage from './pages/dashboardPage/DashboardPage';
import HomePage from './pages/homePage/HomePage';
import { LoginPage } from './pages/loginPage/LoginPage';
import ProjectsPage from './pages/projectsPage/ProjectsPage';
import Theme from './theme/Theme';
import { getItem } from './utils/UI';

Amplify.configure(awsmobile);
const { Content, Sider, Footer } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
const leftNavMenu: MenuItem[] = [
  getItem('Dashboard', 'dashboard', <DashboardRounded fontSize="large" />),
  getItem('Projects', 'projects', <DashboardRounded fontSize="large" />),
  getItem('Services', 'services-page', <DashboardRounded fontSize="large" />),
];

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [activeMenuKey, setActiveMenuKey] = useState<string>('');

  useEffect(() => {
    setActiveMenuKey(location.pathname.substring(1));
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        setUsername(user.username);
        setIsLoggedIn(true);
      })
      .catch(() => {
        navigate('/login');
        setIsLoggedIn(false);
      });
  }, [navigate]);

  const handleSignout = async () => {
    await Auth.signOut();
    navigate('/login');
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <NavBar
        collapsed={collapsed}
        handleSignout={handleSignout}
        setCollapsed={setCollapsed}
        isLoggedIn={isLoggedIn}
        username={username}
      />
      <Layout>
        {isLoggedIn ? (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={260}
            collapsedWidth={100}
            style={{
              borderRight: `1px solid ${Theme.token.navBorderPrimary}`,
              zIndex: '10',
            }}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Menu
              className="left-menu"
              style={{
                backgroundColor: Theme.token.navBgPrimary,
                height: '100%',
                borderRight: 0,
                padding: '1rem 0.5rem',
                color: 'black',
                fontWeight: '600',
              }}
              items={leftNavMenu}
              defaultSelectedKeys={[activeMenuKey]}
              onClick={(item: any) => navigate(`/${item.key}`)}
            />
          </Sider>
        ) : null}
        <Layout>
          <Content style={{}}>
            <Routes>
              <Route path="/" element={<HomePage path="" />} />
              <Route path="/services" element={<HomePage path="services" />} />
              <Route
                path="/portfolio"
                element={<HomePage path="portfolio" />}
              />
              <Route path="/about-us" element={<HomePage path="about-us" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/services" element={<ProjectsPage />} />
            </Routes>
          </Content>
          <Footer
            style={{
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              borderTop: `1px solid ${Theme.token.navBorderPrimary}`,
              backgroundColor: Theme.token.navFooterPrimary,
            }}
          >
            <Button
              href="https://www.wsp.com/en-gb"
              size="large"
              type="link"
              icon={getIcon('wsp-red-logo')}
            />
          </Footer>
        </Layout>

        <Drawer
          title="Filter Drawer"
          placement="right"
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
      </Layout>
    </Layout>
  );
};

export default App;
