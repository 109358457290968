import { handleError, handleResponse } from "../configs/apiMiddleware";
import { defineCancelApiObject } from "../configs/axiosUtils";
import { api_project } from "../configs/axiosConfig";

// defining the cancel API object for ProductAPI
let cancelApiObject: any;

export const ProjectAPI = {
  async getAll (cancel = false) {
    return new Promise((resolve, reject) => {
      api_project
        .request({
          url: `/projects?select=*`,
          method: "GET",
          headers: {
            apiKey:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZranBkeXJvam5ic2lhZGRqd3J3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA0MTc0NDcsImV4cCI6MTk4NTk5MzQ0N30.EQmbmTrBMN7DzgnucMC4dAmMNaibEC8opNUV7uCU6O4",
          },
          signal: cancel
            ? cancelApiObject[""].handleRequestCancellation().signal
            : undefined,
        })
        .then((d) => resolve(handleResponse(d)))
        .catch((e) => reject(handleError(e)));
    });
  },
};

cancelApiObject = defineCancelApiObject(ProjectAPI);


