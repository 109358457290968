import { useQuery } from '@tanstack/react-query';
import { Card, Space, Typography } from 'antd';
import { connect } from 'react-redux';
import { getAllProjectsAction } from '../../redux/actions/ProjectActions';
import { PageContainer } from '../../theme/Global.css';

const { Title } = Typography;

interface Project {
  id: string;
  name: string;
  description: string;
}

interface stateProps {
  projectList: [];
}

interface dispatchProps {
  getAllProjects: () => Promise<any>;
}

type Props = stateProps & dispatchProps;

const DashboardPage = ({ projectList, getAllProjects }: Props) => {
  const getProjectQuery = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
  });

  if (getProjectQuery.isLoading) {
    return <Title level={2}>Loading ...</Title>;
  }

  if (getProjectQuery.isError) {
    return <pre>{JSON.stringify(getProjectQuery.error)}</pre>;
  }
  return (
    <PageContainer>
      <Title>Dashboard Page</Title>
      <Space>
        {projectList.map((project: Project) => (
          <Card
            key={project.id}
            title={project.name}
            bordered
            hoverable
            style={{ width: 300 }}
          >
            <p>{project.description}</p>
          </Card>
        ))}
      </Space>
    </PageContainer>
  );
};

const mapDispatchToProps = {
  getAllProjects: getAllProjectsAction,
};

const mapStateToProps = (state: any) => ({
  projectList: state.project.projectList,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
