import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Navigate } from 'react-router';
import { components, formFields } from '../../utils/aws';
import { LoginStyles } from './LoginPage.css';

export const LoginPage = () => (
  <LoginStyles>
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp
      className="authenticator-container"
    >
      {() => <Navigate to="/" />}
    </Authenticator>
  </LoginStyles>
);
