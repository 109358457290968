import { SvgIcon } from '@mui/material';

type FunctionObject = { [key: string]: Function };

const IconsCtx: FunctionObject = {
  'wsp-dark-logo': () => (
    <SvgIcon viewBox="0 0 569.77332 270.93332">
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,270.93333)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            fill="#0b0906"
            stroke="none"
            d="m 3977.27,1482.89 c 0,230.65 -84.34,423.06 -194.66,549.11 h 320.54 c 105.12,-128.16 170.13,-346.15 170.13,-549.11 v -0.74 c 0,-203.29 -65.21,-421.64 -170.63,-549.709 h -320.57 c 110.59,126.029 195.19,318.699 195.19,549.709 v 0.74"
          />
          <path
            id="path16"
            fill="#0b0906"
            stroke="none"
            d="M 295.5,2032.04 H 0 L 405.52,932.309 H 562.574 L 631.793,1120.03 295.5,2032.04"
          />
          <path
            id="path18"
            fill="#0b0906"
            stroke="none"
            d="M 1088.17,2032.04 H 792.676 L 1198.19,932.309 h 157.06 l 69.22,187.721 -336.3,912.01"
          />
          <path
            id="path20"
            fill="#0b0906"
            stroke="none"
            d="M 3345.25,-0.0117188 V 2032 H 3062.52 V -0.0117188 h 282.73"
          />
          <path
            id="path22"
            fill="#0b0906"
            stroke="none"
            d="m 1724.41,1868.9 c 4.63,56.32 14.79,111.85 38.15,163.1 h 258.63 c -21.61,-39.03 -41.91,-110.15 -18.84,-180.82 20.86,-63.88 65.07,-107.42 120.79,-143.32 65.96,-42.52 143.01,-66.21 213.78,-99.16 74.18,-34.57 146.44,-70.68 208.48,-124.56 90.14,-78.27 144.92,-171.12 153.85,-291.08 1.07,-14.36 1.56,-28.76 1.56,-43.17 0,-75.12 -11.82,-149.65 -45.38,-217.449 h -265.54 c 26.12,44.438 46.67,104.949 42.94,160.859 -9.3,138.52 -178.5,214.29 -320.18,277.32 -21.58,9.6 -42.78,19.03 -63.05,28.51 -62.35,29.14 -123.43,62.59 -176.56,106.71 -54.9,45.59 -96.6,91.25 -122.21,158.21 -22.48,58.75 -30.72,122.45 -27.71,185.1 0.32,6.56 0.75,13.15 1.29,19.75"
          />
        </g>
      </g>
    </SvgIcon>
  ),
  'wsp-red-logo': () => (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 427.33 203.2"
      fontSize="large"
    >
      <path
        fill="#eb3e28"
        d="M402,54.91c0-23.06-8.43-42.3-19.46-54.91h32.05c10.51,12.82,17,34.62,17,54.91V55c0,20.33-6.52,42.16-17.06,55H382.46C393.52,97.36,402,78.09,402,55Z"
        transform="translate(-4.26)"
      />
      <polygon
        fill="#eb3e28"
        points="29.55 0 0 0 40.55 109.97 56.26 109.97 63.18 91.2 29.55 0"
      />
      <polygon
        fill="#eb3e28"
        points="108.82 0 79.27 0 119.82 109.97 135.53 109.97 142.45 91.2 108.82 0"
      />
      <polygon
        fill="#eb3e28"
        points="334.52 203.2 334.52 99.7 334.52 97.02 334.52 88.83 334.52 17.27 334.52 11.62 334.52 8.34 334.52 0.01 306.25 0.01 306.25 88.83 306.25 203.2 334.52 203.2"
      />
      <path
        fill="#eb3e28"
        d="M176.7,16.31A48.7,48.7,0,0,1,180.51,0h25.87a23.69,23.69,0,0,0-1.89,18.09c2.09,6.38,6.51,10.74,12.08,14.33,6.6,4.25,14.3,6.62,21.38,9.91,7.42,3.46,14.64,7.07,20.85,12.46,9,7.83,14.49,17.11,15.38,29.11.11,1.43.16,2.87.16,4.31,0,7.52-1.18,15-4.54,21.75H243.25c2.61-4.44,4.66-10.5,4.29-16.09-.93-13.85-17.85-21.42-32-27.73-2.16-1-4.28-1.9-6.3-2.85a80.74,80.74,0,0,1-17.66-10.67c-5.49-4.56-9.66-9.13-12.22-15.82a45.77,45.77,0,0,1-2.77-18.51C176.6,17.63,176.64,17,176.7,16.31Z"
        transform="translate(-4.26)"
      />
    </SvgIcon>
  ),
  'help-circle-outline': () => (
    <SvgIcon>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  ),
};

export default IconsCtx;
