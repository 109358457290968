import { createSlice } from "@reduxjs/toolkit";

interface ProjectItem {
  id: string;
  title: string;
  description: string;
}

interface ProjectState {
  projectList: ProjectItem[];
}

const projectInitialState: ProjectState = {
  projectList: [],
};

export const projectSlice = createSlice({
  name: "Project",
  initialState: projectInitialState,
  reducers: {
    addProjects: (state, action) => ({
        ...state,
        projectList: [...action.payload],
      }),
  },
});

export const ProjectActions = projectSlice.actions;

export default projectSlice.reducer;
