import axios from "axios";
import { notification } from "antd";

export const api_project = axios.create({
  baseURL: "https://fkjpdyrojnbsiaddjwrw.supabase.co/rest/v1",
});

// defining a custom error handler for all APIs
const errorHandler = (error: any) => {
  const statusCode = error.response?.status;

  if (error.code === "ERR_CANCELED") {
    notification.error({
      placement: "bottomRight",
      description: "API canceled!",
      message: undefined,
    });
    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api_project.interceptors.response.use(undefined, (error) => errorHandler(error));
