import { ProjectAPI } from '../../services/apis/projectAPI';
import { ProjectActions } from '../reducers/ProjectReducer';

export const getAllProjectsAction = () => async (dispatch: any) => new Promise(async (resolve, reject) => {
    try {
      const response = await ProjectAPI.getAll();
      dispatch(ProjectActions.addProjects(response));
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
