import IconsCtx from './_icons';

const getIcon = (name: any) => {
  let icon = IconsCtx[name];

  if (!icon) {
    console.warn(`icon ${name} was not found`);
    icon = IconsCtx['help-circle-outline'];
  }
  return icon();
};

export default getIcon;
