import { useAuthenticator } from '@aws-amplify/ui-react-core';
import { Button } from 'antd';

export const components = {
  SignIn: {
    Header() {
      return <h1 style={{ marginLeft: '30px' }}>Sign In to your account</h1>;
    },

    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className="forgot-pass-container">
          <Button
            type="link"
            className="forgot-pass-btn"
            onClick={toResetPassword}
          >
            Forgot your password?
          </Button>
        </div>
      );
    },
  },
};

export const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'Confirmation Code',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'Confirmation Code',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirmation Code',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};
