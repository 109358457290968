import { theme } from 'antd';

const Theme = {
  token: {
    colorPrimary: '#2192ff',
    colorSuccess: '#00cf95',
    colorWarning: '#f49d1a',
    colorError: '#ff4949',
    navBgPrimary: '#f0f0f0',
    navBorderPrimary: '#d9d9d9',
    navFooterPrimary: '#f0f0f0',
    borderRadius: 5,
    wireframe: false,
    fontFamily: "'Public Sans', sans-serif",
  },
  app: {
    borderRadius: '5px',
    borderWidth: '1px',
  },
  algorithm: theme.defaultAlgorithm,
};

export default Theme;
