import { AccountCircleRounded, MenuRounded } from '@mui/icons-material';
import { Button, Dropdown, Layout, Menu, theme, Typography } from 'antd';
import type { MenuProps } from 'antd/es/menu';
import { useNavigate } from 'react-router';
import Theme from '../../theme/Theme';
import { getItem } from '../../utils/UI';

const { Title } = Typography;
const { Header } = Layout;
const { useToken } = theme;
type MenuItem = Required<MenuProps>['items'][number];

interface propTypes {
  username: string;
  collapsed: boolean;
  isLoggedIn: boolean;
  setCollapsed: (collapsed: any) => void;
  handleSignout: () => void;
}

const topNavMenu: MenuItem[] = [
  getItem('Home', '', null),
  getItem('Services', 'services', null),
  getItem('Portfolio', 'portfolio', null),
  getItem('About Us', 'about-us', null),
];

function NavBar({
  username,
  collapsed,
  setCollapsed,
  isLoggedIn,
  handleSignout,
}: propTypes) {
  const { token } = useToken();
  const navigate = useNavigate();

  const items: MenuProps['items'] = isLoggedIn
    ? [
        {
          key: '1',
          label: `Hi, ${username}`,
        },
        {
          key: '2',
          label: (
            <Button type="link" onClick={handleSignout}>
              Sign Out
            </Button>
          ),
        },
      ]
    : [
        {
          key: '1',
          label: (
            <Button type="link" onClick={() => navigate('/login')}>
              Sign In
            </Button>
          ),
        },
      ];

  return (
    <Header
      style={{
        backgroundColor: Theme.token.navBgPrimary,
        position: 'relative',
        display: 'flex',
        borderBottom: `1px solid ${Theme.token.navBorderPrimary}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        padding: '0.25rem 2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        {isLoggedIn ? (
          <Button
            className="round-header-btn"
            shape="circle"
            size="large"
            style={{
              boxShadow: 'none',
            }}
            icon={<MenuRounded style={{ color: 'black' }} />}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Title
            level={3}
            style={{
              padding: '0',
              margin: '0',
              display: 'inline',
              lineHeight: '0',
              color: token.colorPrimary,
            }}
          >
            DIM Docks
          </Title>
          <p
            style={{
              padding: '0',
              margin: '0',
              display: 'inline',
              lineHeight: '0',
              opacity: '0.75',
            }}
          >
            Super Admin
          </p>
        </div>
      </div>
      <Menu
        style={{
          backgroundColor: Theme.token.navBgPrimary,
          justifyContent: 'center',
          borderBottom: Theme.token.navBorderPrimary,
          alignItems: 'center',
          fontWeight: '600',
          width: '60%',
        }}
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={['']}
        items={topNavMenu}
        onSelect={(e: any) => {
          navigate(`/${e.key}`);
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button
            className="round-header-btn"
            shape="circle"
            size="large"
            icon={<AccountCircleRounded style={{ color: 'black' }} />}
          />
        </Dropdown>
      </div>
    </Header>
  );
}

export default NavBar;
